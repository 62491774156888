/* eslint-disable */
import $ from 'jquery';


/*
* Esign Social Feed
*
* API:
*   change social key
* TYPES:
*   enable ajax calls
* USE_GRID:
*   true requires GRID
*   false requires feed ajax call
* GRID:
*   set order of types
* GRID_REPEAT:
*   how many times
* GRID_REPEAT_EXCLUDE:
*   exclude types from repeat
*
*/

window.social = window.social || {};
var limit = 10;
var page = 1;
var dataEnded = false;
var currentData = [];

social.cacheSelectors = function () {

  social.data = {
    feed: null,
    instagram: null,
    twitter: null,
    facebook: null,
    pinterest: null
  };

  social.cache = {
    // const
    API: 'https://apps.esign.eu/social/95/',
    TYPES: {
      feed: true,
      instagram: false,
      twitter: false,
      facebook: false,
      pinterest: false
    },
    USE_GRID: false,
    GRID: [
      'instagram',
      // 'facebook',
      'twitter',
      'instagram',
      // 'instagram',
      // 'twitter'
    ],
    GRID_REPEAT: 30,
    GRID_REPEAT_EXCLUDE: [
    ],

    // vars
    typesActive: 0,
    typesCalled: 0,

    // elements
    $socialGridTemplates: $('#social-grid-templates')
  };

  social.cache.$feedTemplate = social.cache.$socialGridTemplates.find('.feed-template');
  social.cache.$instagramTemplate = social.cache.$socialGridTemplates.find('.instagram-template');
  social.cache.$twitterTemplate = social.cache.$socialGridTemplates.find('.twitter-template');
  social.cache.$facebookTemplate = social.cache.$socialGridTemplates.find('.facebook-template');
  social.cache.$pinterestTemplate = social.cache.$socialGridTemplates.find('.pinterest-template');

};

social.init = function () {
  // console.log('social.js');
  // check if social grid exists
  var $socialGridHolder = $('#social-grid-holder');
  if ($socialGridHolder.length) {

    social.cacheSelectors();
    social.cache.$holder = $socialGridHolder;

    // api calls
    social.getData();
    social.triggerData();
  }
};

social.showItems = function () {
  if(!social.cache.USE_GRID) {
    if(social.data.feed) {
      for(var i=0, j=social.data.feed.length; i < j; i++) {
        var allData = social.data.feed[i];

        if (
          (allData.text.indexOf('#Dankjewel') >= 0 || allData.text.indexOf('#dankjewel') >= 0)
          && (allData.text.indexOf('#voka') >= 0 || allData.text.indexOf('#Voka') >= 0)
        ) {
          social.addItem(social.data.feed[i]);
        }
      }
    }
  } else {
    for (var repeat=0; repeat < social.cache.GRID_REPEAT; repeat++) {
      for(var tI = 0, tJ = social.cache.GRID.length; tI < tJ; tI++) {
        var type = social.cache.GRID[tI],
          show = true;

        if (repeat > 0 && social.cache.GRID_REPEAT_EXCLUDE.indexOf(type) >= 0) {
          show = false;
        }

        if (show) {
          social.showGridItem(type);
        }
      }
    }
  }
};

// show item from template
social.showGridItem = function (type) {
  var socialData = social.data[type];

  if(socialData && socialData.length > 0) {
    var data = socialData.shift();
    social.addItem(data);
  }
};

// add item to holder
social.addItem = function (data) {
  var $template = social.cache.$feedTemplate.clone();
  var $item;
  // console.log(data);
  // select template
  switch (data.type) {
    case 'twitter':
      $item = social.cache.$twitterTemplate.clone();
      break;
    case 'instagram':
      $item = social.cache.$instagramTemplate.clone();
      break;
    case 'facebook':
      $item = social.cache.$facebookTemplate.clone();
      break;
    case 'pinterest':
      $item = social.cache.$pinterestTemplate.clone();
      break;
  }

  if($item.length < 1) {
    $item = social.cache.$feedTemplate.clone();
  }

  if(data.user_name != null){
    var $user = $item.find('.user');
    if ($user.length) {
      var user = '@' + data.user_name;
      $user.html(user);
    }
  }

  // text
  var $text = $item.find('.text');
  if ($text.length) {
    if (data) {
      var text = data.text;
      var newText = '';
      var wordsLimit = 20;
      if($text.data('words-limit')){
        wordsLimit = $text.data('words-limit');
      }
      var wordsArray = text.split(' ');

      if(wordsLimit && wordsLimit > 0 && wordsLimit < wordsArray.length) {
        newText = wordsArray.splice(0, wordsLimit).join(' ') + '...';
      } else {
        newText = text;
      }

      $text.html(newText);

    } else {
      $text.remove();
    }
  }

  // photo
  var $photo = $item.find('.photo');
  if ($photo.length) {
    if (data.photo) {

      if ($photo.prop('tagName').toLowerCase() === 'img') {
        $photo.attr('src', data.photo);
      } else {
        $photo.css('background-image', 'url(' + data.photo + ')');
      }

    } else {
      $photo.remove();
      $item.addClass('no-photo');
    }
  }

  // likes
  var $likes = $item.find('.likes');
  if ($likes.length) {
    if (data.likes) {
      $likes.text(data.likes);
    } else {
      $likes.remove();
    }
  }

  // url
  var $url = $item.find('.url');
  if ($url.length && data.url) {
    $url.attr('href', data.url);
    $url.attr('target', '_blank');
    //TODO: a-tags filteren en dan pas erin!!!!!! splice?
    // $url.attr('title', data.text);
  }

  social.cache.$holder.append($item);

};

social.getData = function () {
  if(!dataEnded){
    social.cache.typesCalled = 0;
    social.cache.typesActive = 0;

    for (var type in social.cache.TYPES) {
      // loop calls
      if (social.cache.TYPES[type] && social.cache.TYPES[type] === true) {
        social.cache.typesActive = social.cache.typesActive + 1;
        social.call(type);
      }
    }
  }
};

social.triggerData = function () {
  $(window).on('scroll', function (){
    if(currentData.responseJSON.length === 0){ dataEnded = true; } else {
      var timer;
      clearTimeout(timer);
      timer = setTimeout(function (){
        // console.log('scrolling');
        var grid = $('#social-grid-holder');
        $.each(grid, function (key, value) {
          var scrollHeight = $(window).scrollTop() + $(window).height();
          var height = $(value).outerHeight();
          var gridPosition = $(value).offset().top;
          var trigger = gridPosition + height;
          // console.log(trigger);
          // console.log(scrollHeight);
          if(trigger <= scrollHeight){
            setTimeout(function(){
              page = page + 1;
              social.getData();
            }, 1000);
          }
        });
      },100);
    }
  })
}

social.call = function (type) {
  // console.log("i'm called");
  // console.log(social.cache.API);
  $.ajax({
    url: social.cache.API + 'feed?limit=10&page=' + page,
    data: {},
    dataType: 'jsonp',
    success: function(data) {
      social.data[type] = data;
    },
    complete: function(data) {
      social.cache.typesCalled = social.cache.typesCalled + 1;
      if(social.cache.typesCalled === social.cache.typesActive) {
        currentData = data;
        social.showItems();
      }

    }
  });

};

$(social.init);
