import RecaptchaForm from '../components/forms/RecaptchaForm';

export default class Contact {
  constructor() {
    // Recaptcha submit handler for each form
    const contactForm = new RecaptchaForm('#form-opinion');
    window.submitRecaptchaFormContact = () => {
      contactForm.submitCallback();
    };

    document.forms.opinion.submit.disabled = true;

    Array.prototype.forEach.call(document.forms.opinion.status_id, (radio) => {
      radio.addEventListener('change', () => {
        if (document.forms.opinion.submit.disabled) {
          document.forms.opinion.submit.disabled = false;
        }
      });
    });
  }
}
